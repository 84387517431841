// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useToken } from "hooks";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "common/components";
import { BASE_URL } from "./use-auth";

// const BASE_URL = "https://solaceradapi.herokuapp.com/api";

export const GetSpecialistTransactions = (userId, { page, dataPerPage }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(
    `study/user/transactions/${userId}?pageNo=${page}&perPage=${dataPerPage}`
  );
  const [totalTranx, setTotalTranx] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const transactions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}${url}`, {
          headers: { "x-auth-token": token },
        });
        const { data: responseObj } = response.data;
        setTotalTranx(responseObj.count);
        setPerPage(responseObj.perPage);
        setData(responseObj.tranx);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
        setIsLoading(false);
      }
    };
    transactions();
  }, [token, userId, url, page, dataPerPage]);
  const path = (currentPage, perPage) => {
    setUrl(
      `/study/user/transactions/${userId}/?pageNo=${currentPage}&perPage=${perPage}`
    );
    history.push(
      `/transaction-history/specialist/${userId}?pageNo=${currentPage}&perPage=${perPage}`
    );
  };
  return { data, error, isLoading, path, totalTranx, perPage };
};

export const GetDiagnosisCentreTransactions = (
  orgId,
  { page, dataPerPage }
) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalTranx, setTotalTranx] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [url, setUrl] = useState(
    `study/org/transactions/${orgId}?pageNo=${page}&perPage=${dataPerPage}`
  );
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const transactions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/org/transactions/${orgId}?pageNo=${page}&perPage=${dataPerPage}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response.data;
        setTotalTranx(responseObj.count);
        setPerPage(responseObj.perPage);
        setData(responseObj.tranx);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
        setIsLoading(false);
      }
    };
    transactions();
  }, [token, orgId, url, page, dataPerPage]);
  const path = (currentPage, perPage) => {
    setUrl(
      `/study/org/transactions/${orgId}/?pageNo=${currentPage}&perPage=${perPage}`
    );
    history.push(
      `/transaction-history/diagnosis-centre/${orgId}?pageNo=${currentPage}&perPage=${perPage}`
    );
  };

  return { isLoading, data, error, totalTranx, perPage, path };
};

export const GetTopTransactions = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalTranx, setTotalTranx] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const transactions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/transactions?pageNo=1&perPage=5`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response.data;
        setTotalTranx(responseObj.count);
        setPerPage(responseObj.perPage);
        setData(responseObj.tranx);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
        setIsLoading(false);
      }
    };
    transactions();
  }, [token]);

  return { isLoading, data, error, totalTranx, perPage };
};

export const GetTransactions = ({ page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalTranx, setTotalTranx] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const token = useToken();
  useEffect(() => {
    const transactions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/transactions?pageNo=${page}&perPage=${dataPerPage}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response?.data;
        setTotalTranx(responseObj?.count);
        setPerPage(responseObj?.perPage);
        setData(responseObj?.tranx);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
        setIsLoading(false);
      }
    };
    transactions();
  }, [token, page, dataPerPage]);

  return { isLoading, data, error, totalTranx, perPage };
};
