// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { BASE_URL } from "./use-auth";

export const GetModalities = ({ page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalModalities, setTotalModalities] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [url, setUrl] = useState("/study/modality");
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const getModalities = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/modality/?pageNo=${page}&perPage=${dataPerPage}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response.data;
        setTotalModalities(response.data.count);
        setPerPage(response.data.perPage);
        setData(responseObj);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
      }
    };
    getModalities();
  }, [token, url, page, dataPerPage]);

  const path = (currentPage, perPage) => {
    setUrl(`/study/modality/?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/modalities?pageNo=${currentPage}&perPage=${perPage}`);
  };

  return {
    data,
    isLoading,
    totalModalities,
    perPage,
    error,
    path,
  };
};

export const AddModality = () => {
  const addModality = async () => {
    try {
      const response = await axios.post(`/study/modality`);
      const { data } = response.data;
      toast.success(data.message);
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message);
    }
  };
  return { addModality };
};

export const DeleteModality = () => {
  const token = useToken();
  const deleteModality = async (modalityId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}study/modality/${modalityId}`,
        {
          headers: { "x-auth-token": token },
        }
      );
      toast.success(response.data.message);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  return { deleteModality };
};
