import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  isLoading: false,
  isError: false,
  isLoggedIn: false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
}

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    pending: (state) => {
      state.isLoading = true
      state.isError = false
      state.token = null
      state.user = null
      state.isLoggedIn = false
    },
    success: (state, { payload }) => {
      state.isLoading = false
      state.isError = false
      state.token = payload.token
      state.user = payload.user
      state.isLoggedIn = true
    },
    failed: (state) => {
      state.isLoading = false
      state.isError = true
      state.token = null
      state.user = null
      state.isLoggedIn = false
    },
    signOut: (state) => {
      state.isLoading = false
      state.isError = false
      state.token = null
      state.user = null
      state.isSuccess = true
      state.isLoggedIn = false
    },
  },
})
export const { pending, success, failed, signOut } = AuthSlice.actions
export default AuthSlice.reducer
