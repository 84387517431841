import React, { lazy, Suspense } from "react";
import { ErrorPage } from "pages";
import { Spinner } from "common/components";
import ProtectedRoute from "./protected-route";
import { Switch, Route } from "routes";
import { SignOut, useToken } from "hooks";
import Modal from "../common/components/modal/modal";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const AddAdmin = lazy(() => import("../pages/forms/admin/add-admin"));
const DiagnosisCentres = lazy(() =>
  import("../pages/tables/diagnosis-centre/table")
);
const Specialists = lazy(() => import("../pages/tables/specialist/table"));
const SpecialistAccountDetails = lazy(() =>
  import("../pages/forms/specialist/specialist-account-details")
);
const StudyDetails = lazy(() => import("../pages/forms/study/study-details"));
const DiagnosisCentreDetails = lazy(() =>
  import("../pages/forms/diagnosis-centre/diagnosis-centre-details")
);
const Studies = lazy(() => import("../pages/tables/studies/table"));
const Modalities = lazy(() => import("../pages/tables/modalities/table"));
const Transactions = lazy(() => import("../pages/tables/transactions/table"));

const AdminAccountDetails = lazy(() =>
  import("../pages/forms/admin/admin-account-details")
);
const Qualifications = lazy(() =>
  import("../pages/tables/qualifications/table")
);
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const Login = lazy(() => import("../pages/login/login"));
const Admin = lazy(() => import("../pages/tables/admin/index"));
const PdfViewer = lazy(() => import("./../pages/pdf-viewer/pdf-viewer"));

const AppRoutes = () => {
  const isAuthenticated = useToken();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route
          path="/"
          render={() => {
            return isAuthenticated ? (
              <Redirect to={"/dashboard"} />
            ) : (
              <Redirect to={"/login"} />
            );
          }}
          exact
        />
        <ProtectedRoute path="/add-admin" component={AddAdmin} />
        <ProtectedRoute path="/admin/:id" component={AdminAccountDetails} />
        <Route path="/admin" component={Admin} />
        <Route path="/modal" component={Modal} />
        <ProtectedRoute
          exact
          path="/diagnosis-centres"
          component={DiagnosisCentres}
        />
        <Route
          path="/diagnosis-centres/:id"
          component={DiagnosisCentreDetails}
          exact
        />
        <Route path="/study/:id" component={StudyDetails} exact />
        <ProtectedRoute
          path="/specialist/:id"
          component={SpecialistAccountDetails}
          exact
        />
        {/* <ProtectedRoute
          path="/study/:id"
          component={SpecialistAccountDetails}
          exact
        /> */}
        <ProtectedRoute path="/specialist" component={Specialists} exact />
        <ProtectedRoute path="/studies" component={Studies} exact />
        <ProtectedRoute path="/modalities" component={Modalities} />
        <ProtectedRoute path="/transactions" component={Transactions} />
        <ProtectedRoute path="/qualifications" component={Qualifications} />
        <ProtectedRoute path="/logout" component={SignOut} />
        <Route path="/not-found" component={ErrorPage} />
        <Route path="/login" component={Login} />

        <ProtectedRoute path="/dashboard" component={Dashboard} />

        <ProtectedRoute exact path="/view-pdf" render={() => <PdfViewer />} />
      </Switch>
    </Suspense>
  );
};
export default AppRoutes;
