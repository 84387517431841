import React, { Fragment, useEffect } from "react";
import styles from "./login.module.css";
import { Formik } from "formik";
import { Input, Button, SpinnerBounce } from "common/components";
import { loginValidationSchema } from "schema";
import { useAuth } from "hooks";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "./style";
import { ReactComponent as Elipse } from "./../../assets/images/elipse.svg";
import { ReactComponent as Comment } from "./../../assets/images/comment.svg";
import { ReactComponent as Emoji } from "../../assets/images/emoji-wave.svg";
import { HomeLogo } from "common/components/logo/logo";

const Login = () => {
  const { SignIn, isLoading } = useAuth();
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    if (token !== null) {
      history.push("/dashboard");
    }
  }, [token, history]);
  return (
    <FlexContainer>
      <div className="left">
        <Elipse className="abs-el" />
        <Comment className="abs-com" />
        <HomeLogo color="white" imgClassName="logo" />
        <h1>Sign in to Solace Imaging admin.</h1>
        <p>
          Enter the admin login details to get access the Solace Imaging admin.
        </p>
      </div>
      <div className="right">
        <div className="sm">
          <HomeLogo className="mobile-logo" />
        </div>

        <main>
          <header>
            <Emoji className="wave" />
            <h1>Welcome back!</h1>
          </header>
          <p>Enter admin login credentials...</p>

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidationSchema}
            onSubmit={(value, action) => {
              SignIn(value.email, value.password, action);
            }}
          >
            {(formik) => (
              <Fragment>
                <form
                  className={styles.formContent}
                  onSubmit={formik.handleSubmit}
                >
                  <Input
                    name="email"
                    type="text"
                    formik={formik}
                    placeholder="Enter your Email"
                  />
                  <Input
                    name="password"
                    type="password"
                    formik={formik}
                    placeholder="Enter your Password"
                  />
                  <Button
                    className="loginBtn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span>Validating your data</span>
                        <SpinnerBounce />
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>
                </form>
              </Fragment>
            )}
          </Formik>
        </main>
      </div>
    </FlexContainer>
  );
};
export default Login;
