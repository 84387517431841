// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BASE_URL } from "./use-auth";
// const BASE_URL = 'https://solaceradapi.herokuapp.com/api';
// eslint-disable-next-line
export const useStudySearch = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalStudies, setTotalStudies] = useState(0);
  const [url, setUrl] = useState("study");
  const [perPage, setPerPage] = useState(0);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const getSpecialists = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}${url}`, {
          headers: { "x-auth-token": token },
        });
        const { data: responseObj } = response.data;
        setData(responseObj.studies);
        setPerPage(responseObj.perPage);
        setTotalStudies(responseObj.count);
        setIsLoading(false);
      } catch (err) {
        const data = err.response.data;
        setError(data);
      }
    };
    getSpecialists();
  }, [token, url]);
  const path = (currentPage, perPage) => {
    setUrl(`/study/?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/study-search?pageNo=${currentPage}&perPage=${perPage}`);
  };
  return {
    data,
    isLoading,
    error,
    totalStudies,
    path,
    perPage,
  };
};

export const GetAllStudies = ({ page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalStudies, setTotalStudies] = useState(0);
  const [url, setUrl] = useState("study");
  const [perPage, setPerPage] = useState(0);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const getSpecialists = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/study/?pageNo=${page}&perPage=${dataPerPage}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response.data;
        setData(responseObj.studies);
        setPerPage(responseObj.perPage);
        setTotalStudies(responseObj.count);
        setIsLoading(false);
      } catch (err) {
        const data = err.response.data;
        setError(data);
      }
    };
    getSpecialists();
  }, [token, url, page, dataPerPage]);
  const path = (currentPage, perPage) => {
    setUrl(`/study/?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/studies?pageNo=${currentPage}&perPage=${perPage}`);
  };
  return {
    data,
    isLoading,
    error,
    totalStudies,
    path,
    perPage,
  };
};

export const GetDiagnosisCentreStudies = (orgId, { page, dataPerPage }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useState(0);
  const [totalStudies, setTotalStudies] = useState(0);
  const [url, setUrl] = useState(`study/org/${orgId}`);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const Specialist = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/org/${orgId}?pageNo=${page}&perPage=${dataPerPage}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response.data;
        setData(responseObj.studies);
        setPerPage(responseObj.perPage);
        setTotalStudies(responseObj.count);
        setIsLoading(false);
      } catch (err) {
        const data = err.response.data;
        setError(data);
        setIsLoading(false);
      }
    };
    Specialist();
  }, [token, orgId, url, page, dataPerPage]);
  const path = (currentPage, perPage) => {
    setUrl(`/study/org/${orgId}/?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(
      `/diagnosis-centres/${orgId}?pageNo=${currentPage}&perPage=${perPage}`
    );
  };
  return { data, error, isLoading, totalStudies, perPage, path };
};
export const GetUserStudies = (userId, { page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [perPage, setPerPage] = useState(0);
  const [totalStudies, setTotalStudies] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  const Specialist = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}study/user/${userId}?pageNo=${page}&perPage=${dataPerPage}`,
        {
          headers: { "x-auth-token": token },
        }
      );
      const { data: responseObj } = response.data;
      setPerPage(responseObj.perPage);
      setTotalStudies(responseObj.count);
      setData(responseObj.studies);
      setIsLoading(false);
    } catch (err) {
      const data = err.response.data;
      setError(data);
    }
  };
  useEffect(() => {
    Specialist();
  }, [token, userId, page, dataPerPage]);
  return { data, error, isLoading, totalStudies, perPage };
};

export const GetSpecialistStat = (userId) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  useEffect(() => {
    const SpecialistStat = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/user/transactions/stats/${userId}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        console.log(response.data, "Specialist");
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
        setIsLoading(false);
      }
    };
    SpecialistStat();
  }, [token, userId]);

  return { data, error, isLoading };
};

export const GetDiagnosisCentreStat = (orgId) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  useEffect(() => {
    const OrganizationStat = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}study/org/transactions/stats/${orgId}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
        setIsLoading(false);
      }
    };
    OrganizationStat();
  }, [token, orgId]);

  return { data, error, isLoading };
};
export const StudySearch = (queryValue) => {
  const token = useToken();
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   const search = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}study/search?search=${queryValue}`,
  //         {
  //           headers: { "x-auth-token": token },
  //         }
  //       );
  //       setData(response.data.data);
  //       // toast.success(response.data.message)
  //     } catch (err) {
  //       const { data } = err.response;
  //       toast.error(data.message);
  //     }
  //   };
  //   search(queryValue);
  // }, [queryValue, token]);
  return { data };
};

export const GetStudy = (studyId) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  useEffect(() => {
    const Admin = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}study/${studyId}`, {
          headers: { "x-auth-token": token },
        });
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
      }
    };
    Admin();
  }, [token, studyId]);

  return { data, error, isLoading };
};
