import React from 'react';
import styles from './error.module.css';
const ErrorPage = () => {
  return (
    <div className={styles.containerFluid}>
      <div className={styles.container}>
        <h1 className={styles.header}>404</h1>
        <span className={styles.message}>Page Not found</span>
        <span className={styles.message}>
          The requested resource could not be found on this server!
        </span>
      </div>
    </div>
  );
};
export default ErrorPage;
