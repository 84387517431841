import { Link } from "react-router-dom";
import logoblue from "./../../../assets/images/logo-blue.png";
import logo from "./../../../assets/images/logo.png";

export const HomeLogo = ({
  className = "",
  imgClassName = "",
  color = "blue",
  onClick = () => {},
}) => {
  return (
    <Link to="/" className={`homeLogo ${className}`} onClick={onClick}>
      <img
        src={color === "blue" ? logoblue : logo}
        alt="radiology's logo"
        className={imgClassName}
      />
    </Link>
  );
};
