import React from 'react'
// import styles from './main.module.css'
import { AppRoutes } from 'routes'
const Main = () => {
  // const history = useHistory();
  // const navbar = !isFullPageLayout ? <Navbar /> : "";
  // const sidebar = !isFullPageLayout ? <Sidebar user={user} /> : "";
  // useEffect(() => {
  //   const fullPageRoutes = ['/login']
  //   for (let i = 0; i < fullPageRoutes.length; i++) {
  //     if (history.location.pathname === fullPageRoutes[i]) {
  //       setIsFullPageLayout(true)
  //     } else {
  //       setIsFullPageLayout(false)
  //     }
  //   }
  // }, [history, isFullPageLayout])
  // if (isFullPageLayout) {
  //   return (
  //     <div className={styles.containerFluid}>
  //       <Route path="/login" component={Login} />
  //     </div>
  //   );
  // }
  // if (!isFullPageLayout) {
  return (
    // <div className={styles.container}>
    //   {sidebar}
    //   <div className={styles.main}>
    //     {navbar}
    <AppRoutes />
    //   </div>
    // </div>
  )
  // }
}
export default Main
