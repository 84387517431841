// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { BASE_URL } from "./use-auth";

export const GetQualifications = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("user/qualifications");
  const [totalQualification, setTotalQualification] = useState(0);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const getQualifications = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}${url}`, {
          headers: { "x-auth-token": token },
        });
        const { data: responseObj } = response.data;
        setData(responseObj);
        const admins = responseObj.length;
        setTotalQualification(admins);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
      }
    };
    getQualifications();
  }, [token, url]);
  const path = (currentPage, perPage) => {
    setUrl(`/user/qualifications?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/qualifications?pageNo=2&perPage=${perPage}`);
  };
  return {
    data,
    path,
    isLoading,
    error,
    totalQualification,
  };
};

export const AddQualification = () => {
  const token = useToken();
  const addQualification = async () => {
    try {
      const response = await axios.post(`/user/qualification`, {
        headers: { "x-auth-token": token },
      });
      const { data } = response.data;
      toast.success(data.message);
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message);
    }
  };
  return { addQualification };
};
