import React, { Fragment } from 'react';
import styles from './message.module.css';
const ValidationMessage = ({ formik, name }) => {
  return (
    <Fragment>
      {formik.errors[name] && formik.touched[name] ? (
        <div className={styles.validationMessage}>{formik.errors[name]}</div>
      ) : null}
    </Fragment>
  );
};
export default ValidationMessage;
