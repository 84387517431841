import React from 'react';
import styles from './input.module.css';
import ValidationMessage from '../validation-message/message';
import { usePasswordVisibility } from 'hooks';
import { InputGroupContainer } from './style';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Input = ({ type, name, formik, disabled, placeholder }) => {
  const { visible, setIsVisible } = usePasswordVisibility();

  return (
    // <div className={styles.formGroup}>
    <InputGroupContainer
      password={type === 'password' ? true : false}
      error={formik.errors[name] && formik.touched[name]}>
      <label htmlFor={name} className={styles.formLabel}>
        {name}
      </label>
      {/* <div
        className={
          formik.errors[name] && formik.touched[name]
            ? styles.inputFormInvalid
            : styles.inputForm
        }
      > */}
      <div>
        <input
          id={name}
          disabled={disabled}
          name={name}
          type={visible ? 'text' : type}
          className={styles.formControl}
          placeholder={placeholder}
          {...formik.getFieldProps({ name })}
        />
        {type === 'password' &&
          (!visible ? (
            <FiEyeOff
              className='eye-icon'
              onClick={() => {
                setIsVisible(true);
              }}
            />
          ) : (
            <FiEye
              className='eye-icon'
              onClick={() => {
                setIsVisible(false);
              }}
            />
          ))}
      </div>

      {/* {type === "password" ? (
        <div className={styles.iconsCont}>
          <i
            onClick={() => setIsVisible(!visible)}
            className={`${styles.icon} fa-regular ${
              visible ? "fa-eye" : "fa-eye-slash"
            }`}
          ></i>
        </div>
      ) : null} */}
      {/* </div> */}
      <ValidationMessage formik={formik} name={name} />
    </InputGroupContainer>

    // </div>
  );
};
export default Input;
