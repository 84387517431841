import React from 'react'
// import styles from './button.module.css'
import { SideNavButton, SideNavLinkContainer, ThemeButtonContainer } from './style'
import { ReactComponent as Logout } from './../../../assets/icons/logout.svg'

const Button = ({ children, onClick, disabled, className, type, width }) => {
  return (
    <ThemeButtonContainer
      type={type || 'submit'}
      onClick={onClick}
      className={className}
      disabled={disabled}>
      {children}
    </ThemeButtonContainer>
  )
}
export default Button

export const SideNavLink = ({
  // active,
  path,
  name,
  Icon,
  unread,
}) => {
  return (
    <SideNavLinkContainer to={path} unread={unread} activeClassName='active'>
      <div>
        {/* <Icon /> */}
        <i className={Icon + ' nav__icon'} />
        {name}
      </div>
      {unread && <span className='unread'>{unread}</span>}
    </SideNavLinkContainer>
  )
}

export const SideButton = ({ onClick }) => {
  return (
    <SideNavButton className='btn btn__large btn__icon' onClick={onClick}>
      <span>
        <Logout />
      </span>
      Logout
    </SideNavButton>
  )
}
