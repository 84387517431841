import styled from "styled-components";

export const FlexContainer = styled.section`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;

  p {
    font-size: 1.6rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  div.left {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--blue);
    padding: 2.8em 3.5em;
    width: 530px;
    max-width: 100%;
    color: white;
    min-height: 100vh;
    img.logo {
      height: 42px;
    }
    h1 {
      font-size: 40px;
      margin-top: 100px;
    }
    h6 {
      font-size: 18px;
      margin-top: 1.5em;
      font-weight: 400;
      line-height: 34px;
    }

    svg.abs-el {
      position: absolute;
      bottom: 0;
      right: 80px;
    }

    svg.abs-com {
      position: absolute;
      left: 35px;
      bottom: 28px;
    }

    @media (max-width: 1200px) {
      width: 400px;
    }

    @media (max-width: 1200px) {
      width: 100%;
      display: none;
    }
  }

  .wave {
    margin-bottom: 2em;
  }

  @media (max-width: 650px) {
    header {
      display: flex;
      margin-top: 2em;
      justify-content: center;
      h1 {
        margin-top: 0 !important;
      }
      .wave {
        margin-bottom: 0;
        margin-right: 1em;
      }
    }
  }

  small {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #425466;
  }

  .loginBtn {
    margin: 5rem 0;
  }

  div.right {
    flex: 1;
    width: 100%;
    background: #f7fafc;
    padding: 5.8em 3.5em;
    min-height: 100vh;

    div.sm {
      display: none;
    }

    img.logo {
      height: 42px;
      @media (max-width: 900px) {
        height: 32px;
      }
    }
    h1 {
      color: #27272e;
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 1.75em;
    }

    p {
      color: var(--heading-dark);
      line-height: 162.02%;
    }

    @media (max-width: 1200px) {
      padding: 1.6em calc(0.05 * 100vw);
      h1 {
        margin-top: 1rem;
        text-align: center;
        font-size: 3rem;
        margin-bottom: 0;
      }

      p {
        text-align: center;
        margin-bottom: 0;
      }

      .wave {
        display: none;
      }

      div.sm {
        display: block;

        a.mobile-logo {
          margin-bottom: 1em;
          display: block;

          img {
            height: 4rem;
          }
        }

        h6 {
          margin-top: 1em;
          font-weight: 300;
          line-height: 24px;
          font-size: 1em;
        }
      }
    }
  }
`;
