// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { BASE_URL } from "./use-auth";

const useDiagnosisCentre = ({ page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalOrg, setTotalOrg] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [url, setUrl] = useState("org/all");
  const token = useToken();
  const history = useHistory();
  const getDiagnosisCentre = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}org/all/?pageNo=${page}&perPage=${dataPerPage}`,
        {
          headers: { "x-auth-token": token },
        }
      );
      const { data: responseObj } = response.data;
      setData(responseObj.orgs);
      setTotalOrg(responseObj.count);
      setPerPage(responseObj.perPage);
      setIsLoading(false);
    } catch (err) {
      const { data } = err.response;
      setError(data);
    }
  };
  useEffect(() => {
    getDiagnosisCentre();
  }, [token, url, page, dataPerPage]);

  const path = (currentPage, perPage) => {
    setUrl(`/org/all/?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/diagnosis-centres?pageNo=${currentPage}&perPage=${perPage}`);
  };
  return {
    data,
    isLoading,
    error,
    totalOrg,
    path,
    perPage,
    getDiagnosisCentre,
  };
};
export const GetDiagnosisCentre = (orgId) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  const Organizations = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}org/${orgId}`, {
        headers: { "x-auth-token": token },
      });
      setData(response.data.data);
      setIsLoading(false);
    } catch (err) {
      const { data } = err.response;
      setError(data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    Organizations();
  }, [token, orgId]);

  return { data, error, isLoading, Organizations };
};

export const GetDiagnosisCentreFacility = (orgId, { page, dataPerPage }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useState(0);
  const [totalFacility, setTotalFacility] = useState(0);
  const token = useToken();
  const Organizations = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}org/${orgId}/facility?pageNo=${page}&perPage=${dataPerPage}`,
        {
          headers: { "x-auth-token": token },
        }
      );
      setData(response.data.data["facilities"]);
      setPerPage(response.data.perPage);
      setTotalFacility(response.data.data.count);
      setIsLoading(false);
    } catch (err) {
      const { data } = err?.response;
      setError(data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    Organizations();
  }, [token, orgId, page, dataPerPage]);

  return { data, error, isLoading, perPage, totalFacility };
};

export const DeleteDiagnosisCentre = (refresh) => {
  const token = useToken();
  const deleteOrg = async (orgId) => {
    try {
      const response = await axios.delete(`${BASE_URL}org/${orgId}`, {
        headers: { "x-auth-token": token },
      });
      toast.success(response.data.message);
      refresh && refresh();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  return { deleteOrg };
};

export const BlockDiagnosisCentre = (refresh) => {
  const token = useToken();
  const block = async (orgId) => {
    try {
      // eslint-disable-next-line
      const response = await axios.patch(
        `${BASE_URL}org/${orgId}/block`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );
      toast.success(response.data.message);
      refresh && refresh();
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        toast.error(data.message);
      }
    }
  };
  return { block };
};

export const FundCenter = (refresh, close) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  const fund = async (orgId, amount) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line
      const response = await axios.post(
        `${BASE_URL}org/${orgId}/credit`,
        {
          amount,
        },
        {
          headers: { "x-auth-token": token },
        }
      );
      setData(response.data.data);
      toast.success(response.data.message);
      refresh && refresh();
      close();
    } catch (err) {
      setError(err);
      if (err.response) {
        const { data } = err.response;
        toast.error(data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return { fund, data, error, isLoading };
};

export const DiagnosisCentreSearch = (queryValue) => {
  const token = useToken();
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   const search = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}org/search?search=${queryValue}`,
  //         {
  //           headers: { "x-auth-token": token },
  //         }
  //       );
  //       setData(response.data.data);
  //       // toast.success(response.data.message)
  //     } catch (err) {
  //       const { data } = err.response;
  //       toast.error(data.message);
  //     }
  //   };
  //   search(queryValue);
  // }, [queryValue, token]);
  return { data };
};

export const UnBlockDiagnosisCentre = (refresh) => {
  const token = useToken();
  const unBlock = async (orgId) => {
    try {
      // eslint-disable-next-line
      const response = await axios.patch(
        `${BASE_URL}org/${orgId}/unblock`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );
      toast.success(response.data.message);
      refresh && refresh();
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        toast.error(data.message);
      }
    }
  };
  return { unBlock };
};

export default useDiagnosisCentre;
