import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const ThemeButtonContainer = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  width: ${(props) => props.width || '100%'};
  background: ${(props) => (props.disabled ? '#C9CED6' : 'var(--blue)')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 16px 0;
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s linear;
  text-transform: capitalize;

  font-weight: 600;
  font-size: 14px !important;
  line-height: 14px !important;
  /* identical to box height, or 100% */

  /* Theme/White/Default */
  color: #ffffff !important;

  &:hover {
    background: ${(props) => (props.disabled ? '#b1b4ba' : '#3453d1')};
  }
`

export const SideNavButton = styled.button`
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.7rem;
  color: var(--light-1);
  min-height: 0.2rem;
  padding: 1.8rem 2.4rem;
  border-radius: 0 0.5rem 0.5rem 0;
  justify-content: flex-start !important;

  svg {
    margin-right: 2rem;
  }

  &:hover {
    background-color: #ebefff;
  }
`

export const SideNavLinkContainer = styled(NavLink)`
  display: block;

  font-weight: 600;
  font-size: 0.64em;
  line-height: 17px;
  text-decoration: none;
  color: #425466;
  display: flex;
  min-height: 2px;
  align-items: center;
  padding: 18px 24px;
  border-radius: 0 5px 5px 0;

  justify-content: ${({ unread }) => (unread ? 'space-between' : 'flex-start')};

  .nav__icon {
    margin-right: 20px;
  }

  & > div {
    display: flex;
    min-height: 2px;
    align-items: center;
  }

  &:hover {
    background-color: #ebefff;
  }

  &.active {
    color: var(--blue);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 80%;
      width: 2px;
      background-color: var(--blue);
      left: 0;
      top: 0;
      bottom: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  span.unread {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--blue);
    background: #e1e8ff;
    display: block;
    height: 19px;
    width: 28px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
