import * as yup from 'yup'

export const loginValidationSchema = yup.object({
  email: yup.string().email('Invalid email').required('This field is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must include at least an uppercase, symbol and number'
    )
    .required('This field is required'),
})

export const adminValidationSchema = yup.object({
  email: yup.string().email('Invalid email').required('This field is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must include at least an uppercase, symbol and number'
    )
    .required('This field is required'),
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  phoneNumber: yup.string().required('This field is required'),
  role: yup.string().required('This field is required'),
})

export const adminDetailsValidationSchema = yup.object({
  email: yup.string().email('Invalid email').required('This field is required'),
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  phoneNumber: yup.string().required('This field is required'),
  role: yup.string().required('This field is required'),
})

export const diagnosisCentreValidationSchema = yup.object({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required field'),
  email: yup.string().email('Invalid Email Address').required('This field required field'),
  orgName: yup.string().optional(),
  phoneNumber: yup.string(),
  payedReferrer: yup.string(),
  referralCode: yup.string(),
  state: yup.string(),
  balance: yup.string(),
  website: yup.string(),
  createdAt: yup.string(),
})
export const specialistValidationSchema = yup.object({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required field'),
  email: yup.string().email('Invalid Email Address').required('This field required field'),
  password: yup
    .string()
    .required('This field is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'This field must contain at least an uppercase, symbol and number'
    ),
  phoneNumber: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  country: yup.string().required('This field is required'),
  role: yup.string().required('This field is required field'),
})
