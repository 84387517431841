// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "./use-auth";
// const BASE_URL = 'https://solaceradapi.herokuapp.com/api';

const GetAllSpecialist = ({ page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("user");
  const [perPage, setPerPage] = useState(dataPerPage);
  const [totalSpecialist, setTotalSpecialist] = useState(0);
  const token = useToken();
  const history = useHistory();

  const getSpecialists = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        page
          ? `${BASE_URL}user?pageNo=${page}&perPage=${dataPerPage}`
          : `${BASE_URL}user/`,
        {
          headers: { "x-auth-token": token },
        }
      );
      const { data: responseObj } = response.data;
      setData(responseObj.users);
      setTotalSpecialist(responseObj?.count);
      console.log("response obj", responseObj);
      setIsLoading(false);
    } catch (err) {
      const { data } = err.response;
      setError(data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSpecialists();
  }, [token, url, page, dataPerPage]);

  const path = (currentPage, perPage) => {
    setUrl(`/user?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/specialist?pageNo=${currentPage}&perPage=${perPage}`);
  };

  return {
    data,
    isLoading,
    error,
    totalSpecialist,
    path,
    perPage,
    getSpecialists,
  };
};

export const GetSpecialist = (userId) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  const Specialist = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}user/${userId}`, {
        headers: { "x-auth-token": token },
      });
      setData(response.data.data);
      setIsLoading(false);
    } catch (err) {
      const { data } = err.response;
      setError(data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    Specialist();
  }, [token, userId]);

  return { data, error, isLoading, Specialist };
};

export const VerifySpecialist = (refresh) => {
  const token = useToken();
  const verify = async (userId) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}user/${userId}/verify`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );
      toast.success(response.data.message);
      refresh();
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message);
    }
  };
  return { verify };
};

export const SpecialistSearch = (queryValue) => {
  const token = useToken();
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   const search = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}user/search?search=${queryValue}`,
  //         {
  //           headers: { "x-auth-token": token },
  //         }
  //       );
  //       setData(response.data.data);
  //       // toast.success(response.data.message)
  //     } catch (err) {
  //       const { data } = err.response;
  //       toast.error(data.message);
  //     }
  //   };
  //   search();
  // }, [token]);
  return { data };
};

export const BlockSpecialist = (refresh) => {
  const token = useToken();
  const block = async (userId) => {
    try {
      // eslint-disable-next-line
      const response = await axios.patch(
        `${BASE_URL}user/${userId}/block`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );
      toast.success(response.data.message);
      refresh();
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        toast.error(data.message);
      }
    }
  };
  return { block };
};

export const UnBlockSpecialist = (refresh) => {
  const token = useToken();
  const unBlock = async (userId) => {
    try {
      // eslint-disable-next-line
      const response = await axios.patch(
        `${BASE_URL}user/${userId}/unblock`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );
      toast.success(response.data.message);
      refresh();
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        toast.error(data.message);
      }
    }
  };
  return { unBlock };
};

export const DeleteSpecialist = (refresh) => {
  const token = useToken();
  const deleteSpecialist = async (userId) => {
    try {
      const response = await axios.delete(`${BASE_URL}user/${userId}`, {
        headers: { "x-auth-token": token },
      });
      toast.success(response.data.message);
      refresh();
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message);
    }
  };
  return { deleteSpecialist };
};
export default GetAllSpecialist;
