import React from 'react'
import styles from './search-box.module.css'
const SearchBox = ({ placeholder, onChange, value }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inputGroup}>
        <i className={`bx bx-search ${styles.icon}`}></i>
        <input
          onChange={onChange}
          type='search'
          value={value}
          placeholder={placeholder}
          className={styles.formControl}
        />
      </div>
    </div>
  )
}
export default SearchBox
