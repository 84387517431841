// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useToken } from "hooks";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "./use-auth";

const GetAllAdmin = ({ page, dataPerPage }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("admin/all");
  const [totalAdmin, setToTalAdmin] = useState(0);
  const token = useToken();
  const history = useHistory();
  useEffect(() => {
    const getAdmins = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}admin/all?pageNo=${page}&perPage=${dataPerPage}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        const { data: responseObj } = response.data;
        setData(responseObj);
        const admins = responseObj.length;
        setToTalAdmin(admins);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
      }
    };
    getAdmins();
  }, [token, url, page, dataPerPage]);
  const path = (currentPage, perPage) => {
    setUrl(`/admin/all?pageNo=${currentPage}&perPage=${perPage}`);
    history.push(`/admin?pageNo=${currentPage}&perPage=${perPage}`);
  };
  return {
    data,
    path,
    isLoading,
    error,
    totalAdmin,
  };
};

export const CreateAdmin = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  const SignUp = async (user, actions) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}admin/register`,
        {
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          email: user.email,
          phoneNumber: user.phoneNumber,
          role: user.role,
        },
        {
          headers: { "x-auth-token": token },
        }
      );
      const { data } = response.data;
      toast.success(data.message);
      actions.resetForm();
      setIsLoading(false);
    } catch (err) {
      const { data } = err.response;
      setIsLoading(false);
      toast.error(data.message);
      setError(data);
    }
  };

  return { error, isLoading, SignUp };
};
export const GetAdmin = (adminId) => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  useEffect(() => {
    const Admin = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}admin/${adminId}`, {
          headers: { "x-auth-token": token },
        });
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
      }
    };
    Admin();
  }, [token, adminId]);

  return { data, error, isLoading };
};
export const AdminSearch = (queryValue) => {
  const token = useToken();
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   const search = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}admin/search?search=${queryValue}`,
  //         {
  //           headers: { "x-auth-token": token },
  //         }
  //       );
  //       setData(response.data.data);
  //       // toast.success(response.data.message)
  //     } catch (err) {
  //       const { data } = err.response;
  //       toast.error(data.message);
  //     }
  //   };
  //   search(queryValue);
  // }, [queryValue, token]);
  return { data };
};

export const GetDashboardStat = () => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const token = useToken();
  useEffect(() => {
    const Admin = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}study/stat`, {
          headers: { "x-auth-token": token },
        });
        setData(response.data.data);
        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;
        setError(data);
      }
    };
    Admin();
  }, [token]);

  return { data, error, isLoading };
};

export const DeleteAdmin = () => {
  const token = useToken();
  const deleteAdmin = async (userId) => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/${userId}`, {
        headers: { "x-auth-token": token },
      });
      toast.success(response.data.message);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  return { deleteAdmin };
};
export default GetAllAdmin;
