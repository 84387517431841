import React from "react";
import styles from "./modal.module.css";
const Modal = ({ toggleModal, onDelete, type }) => {
  return (
    <>
      <div className={styles.modal}>
        <div onClick={toggleModal} className={styles.overlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <button onClick={toggleModal} className={styles.btnClose}>
                <i className={` ${styles.icon} fa-solid fa-xmark`}></i>
              </button>
            </div>

            <h2 className={styles.header}>Are you sure!?</h2>
            <p className={styles.message}>
              Please confirm that you want to {type || "delete"} this entry.
              This action cannot be reversed!
            </p>
            <button onClick={onDelete} className={styles.deleteBtn}>
              {type || "delete"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
