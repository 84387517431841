import React from 'react';
import styles from './bounce.module.css';
const SpinnerBounce = () => {
  return (
    <div className={styles.bounce}>
      <span className={`${styles.icon} ${styles.icon1}`}></span>
      <span className={`${styles.icon} ${styles.icon2}`}></span>
      <span className={`${styles.icon} ${styles.icon3}`}></span>
    </div>
  );
};

export default SpinnerBounce;
