// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pending, success, failed, signOut } from "../redux";
import { useHistory } from "react-router-dom";
import { useToken } from "hooks";
import { Redirect } from "react-router-dom";
import AuthTimeOut from "./../pages/login/auth-time-out";
import toast from "react-hot-toast";
import axios from "axios";

// export const BASE_URL = "https://solaceradapi.herokuapp.com/api/";
export const BASE_URL = "https://api.solaceimaging.com/api/";
export const useAuth = () => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const { isLoading, isSuccess, isError } = state;
  const token = useToken();
  const history = useHistory();
  const loginTimeOut = AuthTimeOut();
  const SignIn = async (email, password, action) => {
    try {
      dispatch(pending());
      const response = await axios.post(`${BASE_URL}admin/login`, {
        email,
        password,
      });
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
      const expiryTime = 86_400_000;
      const now = new Date();
      const expiryDate = new Date(now.getTime() + expiryTime);
      localStorage.setItem("expiryDate", expiryDate);

      dispatch(success(response.data.data));
      action.resetForm();
      loginTimeOut();
      history.push("/dashboard");
    } catch (err) {
      if (err.response) {
        const { data } = err.response;
        // toast.
        setError(data);
        dispatch(failed());
        console.log("data", data.message);
        toast.error(data.message);
      }
    }
  };
  return { SignIn, isLoading, error, isSuccess, isError, token };
};

export const SignOut = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signOut());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }, [dispatch]);
  return <Redirect to="/login" />;
};
