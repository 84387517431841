import React from "react";
import styles from "./pagination.module.css";
const Pagination = ({
  pages,
  onClick,
  currentPage,
  maxPageLimit,
  minPageLimit,
  handlePrevBtn,
  handleNextBtn,
}) => {
  const renderPages = pages.map((number) => {
    if (number < maxPageLimit + 1 && number > minPageLimit) {
      return (
        <li className={styles.paginationItem} key={number}>
          <button
            onClick={() => onClick(number)}
            className={currentPage === number ? styles.active : styles.link}
          >
            {number}
          </button>
        </li>
      );
    } else {
      return null;
    }
  });
  return (
    <div className={styles.container}>
      <ul className={styles.paginationItems}>
        <li className={styles.paginationItem}>
          <button
            onClick={handlePrevBtn}
            className={styles.link}
            disabled={currentPage === pages[0] ? true : false}
          >
            <i className={`fa-solid ${styles.icon} fa-angles-left`}></i>
          </button>
        </li>
        {renderPages}
        <li className={styles.paginationItem}>
          <button
            onClick={handleNextBtn}
            className={styles.link}
            disabled={currentPage === pages[pages.length - 1]}
          >
            <i className={`fa-solid fa-angles-right ${styles.icon}`}></i>
          </button>
        </li>
      </ul>
    </div>
  );
};
export default Pagination;
