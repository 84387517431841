import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useToken } from "hooks";
import { Redirect } from "react-router-dom";
import toast from "react-hot-toast";
const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const isAuthenticated = useToken();
  const history = useHistory();
  useEffect(() => {
    // Check if the expiry time in local storage has passed
    const expiryDate = localStorage.getItem("expiryDate");
    if (expiryDate) {
      const now = new Date();
      const expiryTime = new Date(expiryDate);
      if (now > expiryTime) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("expiryDate");
        history.push("/login");
        setTimeout(() => {
          // refresh the browser
          window.location.reload();
        }, 1000);
        toast.error("Your session has expired. Please login again.");
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("expiryDate");
      history.push("/login");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};
export default ProtectedRoute;
