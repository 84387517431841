import { useDispatch } from "react-redux";
import { signOut } from "../../redux";
import { useHistory } from "react-router-dom";
const AuthTimeOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginTimeOut = () => {
    setTimeout(() => {
      dispatch(signOut());
      return history.push("/login");
    }, 86400 * 1000);
  };
  return loginTimeOut;
};

export default AuthTimeOut;
