import { Notifications } from "common/components";
import { Main } from "layout/main";
import "./App.css";
function App() {
  return (
    <div className="App">
      <Notifications />
      <Main />
    </div>
  );
}

export default App;
